import * as actionTypes from './actionTypes';
import { ContactActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type ContactStateType = {
  submitMessageLoading: boolean;
  submitMessageError: HttpError;
  submitMessageSuccess: boolean;
};

export type ContactActionType = ContactStateType & {
  type: ContactActionTypes;
};

export const initialState: ContactStateType = {
  submitMessageLoading: false,
  submitMessageError: null,
  submitMessageSuccess: false,
};

const submitMessageStart = (state: ContactStateType): ContactStateType => ({
  ...state,
  submitMessageLoading: true,
  submitMessageSuccess: false,
});

const submitMessageSuccess = (state: ContactStateType): ContactStateType => ({
  ...state,
  submitMessageLoading: false,
  submitMessageError: null,
  submitMessageSuccess: true,
});

const submitMessageFail = (
  state: ContactStateType,
  action: ContactActionType,
): ContactStateType => ({
  ...state,
  submitMessageError: action.submitMessageError,
  submitMessageLoading: false,
});

const reducer = (state = initialState, action: ContactActionType) => {
  switch (action.type) {
    case actionTypes.SUBMIT_MESSAGE_START:
      return submitMessageStart(state);
    case actionTypes.SUBMIT_MESSAGE_SUCCESS:
      return submitMessageSuccess(state);
    case actionTypes.SUBMIT_MESSAGE_FAIL:
      return submitMessageFail(state, action);
    default:
      return state;
  }
};

export default reducer;
