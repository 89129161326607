import * as actionTypes from './actionTypes';
import { User } from '../../domain/User';
import { ListResults } from '../../common/List/List';
import { HttpError } from '../../config/Axios/axios-instance';

export const fetchCurrentUserStart = () => ({
  type: actionTypes.FETCH_ME_START,
});

export const fetchCurrentUserSuccess = (currentUser: User) => ({
  type: actionTypes.FETCH_ME_SUCCESS,
  currentUser,
});

export const fetchCurrentUserFail = () => ({
  type: actionTypes.FETCH_ME_FAIL,
});

export const fetchUserStart = () => ({
  type: actionTypes.FETCH_USER_START,
});

export const fetchUserSuccess = (user: User) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  user,
});

export const fetchUserFail = (userError: HttpError) => ({
  type: actionTypes.FETCH_USER_FAIL,
  userError,
});

export const fetchUsersStart = () => ({
  type: actionTypes.FETCH_USERS_START,
});

export const fetchUsersSuccess = (usersList: ListResults<User>) => ({
  type: actionTypes.FETCH_USERS_SUCCESS,
  usersList,
});

export const fetchUsersFail = (usersError: HttpError) => ({
  type: actionTypes.FETCH_USERS_FAIL,
  usersError,
});

export const createUserStart = () => ({
  type: actionTypes.CREATE_USER_START,
});

export const createUserSuccess = (createdUser: User) => ({
  type: actionTypes.CREATE_USER_SUCCESS,
  createdUser,
});

export const createUserFail = (userCreateError: HttpError) => ({
  type: actionTypes.CREATE_USER_FAIL,
  userCreateError,
});

export const resetCreateUserStore = () => ({
  type: actionTypes.RESET_CREATED_USER,
});

export const updateUserStart = () => ({
  type: actionTypes.UPDATE_USER_START,
});

export const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserFail = (userUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_USER_FAIL,
  userUpdateError,
});

export const deleteUserStart = () => ({
  type: actionTypes.DELETE_USER_START,
});

export const deleteUserSuccess = () => ({
  type: actionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserFail = (cityUserError: HttpError) => ({
  type: actionTypes.DELETE_USER_FAIL,
  cityUserError,
});

export const subscribeNewsletterStart = () => ({
  type: actionTypes.SUBSCRIBE_NEWSLETTER_START,
});

export const subscribeNewsletterSuccess = () => ({
  type: actionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS,
});

export const subscribeNewsletterFail = (
  subscribeNewsletterError: HttpError,
) => ({
  type: actionTypes.SUBSCRIBE_NEWSLETTER_FAIL,
  subscribeNewsletterError,
});

export const resetUserStore = () => ({
  type: actionTypes.RESET_USER_STORE,
});
