import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createCityFail,
  createCityStart,
  createCitySuccess,
  deleteCityFail,
  deleteCityStart,
  deleteCitySuccess,
  fetchCitiesFail,
  fetchCitiesStart,
  fetchCitiesSuccess,
  fetchCityFail,
  fetchCityStart,
  fetchCitySuccess,
  fetchPublicCityOptionsFail,
  fetchPublicCityOptionsStart,
  fetchPublicCityOptionsSuccess,
  fetchPublicTopNavigationItemsFail,
  fetchPublicTopNavigationItemsStart,
  fetchPublicTopNavigationItemsSuccess,
  updateCityFail,
  updateCityStart,
  updateCitySuccess,
} from './actions';
import { getFormData } from '../../utility/form/formData';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { EntityTranslation } from '../../domain/EntityTranslation';

const API_URL = '/cities';

export type CityCreateRequest = {
  name: string;
  description: string;
  isForeign: string;
  image: File;
  images: File[];
  translations: EntityTranslation[];
  seo: {
    slug: string;
    title: string;
    description: string;
    translations: EntityTranslation[];
  };
};

export type CityUpdateRequest = {
  id: number;
  name: string;
  description: string;
  isForeign: string;
  image: File;
  images: File[];
  imageFiles: number[];
  translations: EntityTranslation[];
  seo: {
    slug: string;
    title: string;
    description: string;
    translations: EntityTranslation[];
  };
};

export const fetchCities = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchCitiesStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchCitiesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCitiesFail(err?.response?.data?.error));
    });
};

export const fetchCity = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchCityStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchCitySuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCityFail(err?.response?.data?.error));
    });
};

export const fetchPublicCityOptions = () => (dispatch: Dispatch) => {
  dispatch(fetchPublicCityOptionsStart());
  return axios
    .get(`${API_URL}/options`)
    .then((response) => {
      dispatch(fetchPublicCityOptionsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublicCityOptionsFail(err?.response?.data?.error));
    });
};

export const fetchPublicTopNavigationItems = () => (dispatch: Dispatch) => {
  dispatch(fetchPublicTopNavigationItemsStart());
  return axios
    .get(`${API_URL}/menu-items`)
    .then((response) => {
      dispatch(fetchPublicTopNavigationItemsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublicTopNavigationItemsFail(err?.response?.data?.error));
    });
};

export const createCity =
  (inputs: CityCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createCityStart());
    return axios
      .post(API_URL, getFormData(inputs))
      .then((response) => {
        dispatch(createCitySuccess(response.data));
        showToast(translate(intl, 'SUCCESS.CITY_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createCityFail(err?.response?.data?.message));
      });
  };

export const updateCity =
  (inputs: CityUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateCityStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, getFormData(inputs))
      .then((response) => {
        dispatch(updateCitySuccess());
        showToast(translate(intl, 'SUCCESS.CITY_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateCityFail(err?.response?.data?.message));
      });
  };

export const deleteCity =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteCityStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then((response) => {
        dispatch(deleteCitySuccess());
        showToast(translate(intl, 'SUCCESS.CITY_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteCityFail(err?.response?.data?.message));
      });
  };
