import * as actionTypes from './actionTypes';
import { FaqActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Faq } from '../../domain/Faq';

export type FaqStateType = {
  faqList: ListResults<Faq> | null;
  faqListLoading: boolean;
  faqListError: HttpError;
  faqListUpdateNeeded: boolean;
  faqCreateLoading: boolean;
  faqCreateError: HttpError;
  faqCreateSuccess: boolean;
  createdFaq: Faq | null;
  faqUpdateLoading: boolean;
  faqUpdateError: HttpError;
  faqUpdateSuccess: boolean;
  faq: Faq | null;
  faqLoading: boolean;
  faqError: HttpError;
  faqDeleteLoading: boolean;
  faqDeleteError: HttpError;
  faqDeleteSuccess: boolean;
};

export type FaqActionType = FaqStateType & {
  type: FaqActionTypes;
};

export const initialState: FaqStateType = {
  faqList: null,
  faqListLoading: false,
  faqListError: null,
  faqListUpdateNeeded: false,
  faqCreateLoading: false,
  faqCreateError: null,
  faqCreateSuccess: false,
  createdFaq: null,
  faqUpdateLoading: false,
  faqUpdateError: null,
  faqUpdateSuccess: false,
  faq: null,
  faqLoading: false,
  faqError: null,
  faqDeleteLoading: false,
  faqDeleteError: null,
  faqDeleteSuccess: false,
};

const fetchFaqListStart = (state: FaqStateType): FaqStateType => ({
  ...state,
  faqListLoading: true,
});

const fetchFaqListSuccess = (
  state: FaqStateType,
  action: FaqActionType,
): FaqStateType => ({
  ...state,
  faqListLoading: false,
  faqList: action.faqList,
  faqListUpdateNeeded: false,
});

const fetchFaqListFail = (
  state: FaqStateType,
  action: FaqActionType,
): FaqStateType => ({
  ...state,
  faqListLoading: false,
  faqListError: action.faqListError,
});

const fetchFaqStart = (state: FaqStateType): FaqStateType => ({
  ...state,
  faqLoading: true,
});

const fetchFaqSuccess = (
  state: FaqStateType,
  action: FaqActionType,
): FaqStateType => ({
  ...state,
  faqLoading: false,
  faq: action.faq,
});

const fetchFaqFail = (
  state: FaqStateType,
  action: FaqActionType,
): FaqStateType => ({
  ...state,
  faqLoading: false,
  faqError: action.faqError,
});

const createFaqStart = (state: FaqStateType): FaqStateType => ({
  ...state,
  faqCreateLoading: true,
});

const createFaqSuccess = (
  state: FaqStateType,
  action: FaqActionType,
): FaqStateType => ({
  ...state,
  faqCreateLoading: false,
  faqCreateError: null,
  faqCreateSuccess: true,
  faqListUpdateNeeded: true,
});

const createFaqFail = (
  state: FaqStateType,
  action: FaqActionType,
): FaqStateType => ({
  ...state,
  faqCreateLoading: false,
  faqCreateError: action.faqCreateError,
});

const updateFaqStart = (state: FaqStateType): FaqStateType => ({
  ...state,
  faqUpdateLoading: true,
});

const updateFaqSuccess = (state: FaqStateType): FaqStateType => ({
  ...state,
  faqUpdateLoading: false,
  faqUpdateSuccess: true,
  faqListUpdateNeeded: true,
  faqUpdateError: null,
});

const updateFaqFail = (
  state: FaqStateType,
  action: FaqActionType,
): FaqStateType => ({
  ...state,
  faqUpdateLoading: false,
  faqUpdateError: action.faqUpdateError,
});

const deleteFaqStart = (state: FaqStateType): FaqStateType => ({
  ...state,
  faqDeleteLoading: true,
});

const deleteFaqSuccess = (state: FaqStateType): FaqStateType => ({
  ...state,
  faqDeleteLoading: false,
  faqDeleteSuccess: true,
  faqListUpdateNeeded: true,
});

const deleteFaqFail = (
  state: FaqStateType,
  action: FaqActionType,
): FaqStateType => ({
  ...state,
  faqDeleteLoading: false,
  faqDeleteError: action.faqDeleteError,
});

const resetCreatedFaqStore = (state: FaqStateType): FaqStateType => ({
  ...state,
  createdFaq: null,
  faqCreateLoading: false,
  faqCreateError: null,
});

const resetFaqStore = (): FaqStateType => ({
  ...initialState,
});

const completelyResetFaqStore = (state: FaqStateType): FaqStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: FaqActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_FAQ_LIST_START:
      return fetchFaqListStart(state);
    case actionTypes.FETCH_FAQ_LIST_SUCCESS:
      return fetchFaqListSuccess(state, action);
    case actionTypes.FETCH_FAQ_LIST_FAIL:
      return fetchFaqListFail(state, action);
    case actionTypes.FETCH_FAQ_START:
      return fetchFaqStart(state);
    case actionTypes.FETCH_FAQ_SUCCESS:
      return fetchFaqSuccess(state, action);
    case actionTypes.FETCH_FAQ_FAIL:
      return fetchFaqFail(state, action);
    case actionTypes.CREATE_FAQ_START:
      return createFaqStart(state);
    case actionTypes.CREATE_FAQ_SUCCESS:
      return createFaqSuccess(state, action);
    case actionTypes.CREATE_FAQ_FAIL:
      return createFaqFail(state, action);
    case actionTypes.UPDATE_FAQ_START:
      return updateFaqStart(state);
    case actionTypes.UPDATE_FAQ_SUCCESS:
      return updateFaqSuccess(state);
    case actionTypes.UPDATE_FAQ_FAIL:
      return updateFaqFail(state, action);
    case actionTypes.DELETE_FAQ_START:
      return deleteFaqStart(state);
    case actionTypes.DELETE_FAQ_SUCCESS:
      return deleteFaqSuccess(state);
    case actionTypes.DELETE_FAQ_FAIL:
      return deleteFaqFail(state, action);
    case actionTypes.RESET_CREATED_FAQ_STORE:
      return resetCreatedFaqStore(state);
    case actionTypes.RESET_FAQ_STORE:
      return resetFaqStore();
    case actionTypes.LOGOUT:
      return completelyResetFaqStore(state);
    default:
      return state;
  }
};

export default reducer;
