import * as actionTypes from './actionTypes';
import { CityServiceActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { CityService } from '../../domain/CityService';
import { ListResults } from '../../common/List/List';

export type CityServiceStateType = {
  cityServicesList: ListResults<CityService> | null;
  cityServicesLoading: boolean;
  cityServicesError: HttpError;
  cityServicesListUpdateNeeded: boolean;
  cityServiceCreateLoading: boolean;
  cityServiceCreateError: HttpError;
  cityServiceCreateSuccess: boolean;
  createdCityService: CityService | null;
  cityServiceUpdateLoading: boolean;
  cityServiceUpdateError: HttpError;
  cityServiceUpdateSuccess: boolean;
  cityServiceDeleteLoading: boolean;
  cityServiceDeleteError: HttpError;
  cityServiceDeleteSuccess: boolean;
};

export type CityServiceActionType = CityServiceStateType & {
  type: CityServiceActionTypes;
};

export const initialState: CityServiceStateType = {
  cityServicesList: null,
  cityServicesLoading: true,
  cityServicesError: null,
  cityServicesListUpdateNeeded: false,
  cityServiceCreateLoading: false,
  cityServiceCreateError: null,
  cityServiceCreateSuccess: false,
  createdCityService: null,
  cityServiceUpdateLoading: false,
  cityServiceUpdateError: null,
  cityServiceUpdateSuccess: false,
  cityServiceDeleteLoading: false,
  cityServiceDeleteError: null,
  cityServiceDeleteSuccess: false,
};

const fetchCityServicesStart = (
  state: CityServiceStateType,
): CityServiceStateType => ({
  ...state,
  cityServicesLoading: true,
});

const fetchCityServicesSuccess = (
  state: CityServiceStateType,
  action: CityServiceActionType,
): CityServiceStateType => ({
  ...state,
  cityServicesList: action.cityServicesList,
  cityServicesLoading: false,
  cityServicesError: null,
  cityServicesListUpdateNeeded: false,
});

const fetchCityServicesFail = (
  state: CityServiceStateType,
  action: CityServiceActionType,
): CityServiceStateType => ({
  ...state,
  cityServicesError: action.cityServicesError,
  cityServicesLoading: false,
});

const createCityServiceStart = (
  state: CityServiceStateType,
): CityServiceStateType => ({
  ...state,
  cityServiceCreateLoading: true,
});

const createCityServiceSuccess = (
  state: CityServiceStateType,
  action: CityServiceActionType,
): CityServiceStateType => ({
  ...state,
  cityServiceCreateLoading: false,
  cityServiceCreateError: null,
  cityServiceCreateSuccess: true,
  createdCityService: action.createdCityService,
  cityServicesListUpdateNeeded: true,
});

const createCityServiceFail = (
  state: CityServiceStateType,
  action: CityServiceActionType,
): CityServiceStateType => ({
  ...state,
  cityServiceCreateLoading: false,
  cityServiceCreateError: action.cityServiceCreateError,
});

const updateCityServiceStart = (
  state: CityServiceStateType,
): CityServiceStateType => ({
  ...state,
  cityServiceUpdateLoading: true,
});

const updateCityServiceSuccess = (
  state: CityServiceStateType,
): CityServiceStateType => ({
  ...state,
  cityServiceUpdateLoading: false,
  cityServiceUpdateError: null,
  cityServiceUpdateSuccess: true,
  cityServicesListUpdateNeeded: true,
});

const updateCityServiceFail = (
  state: CityServiceStateType,
  action: CityServiceActionType,
): CityServiceStateType => ({
  ...state,
  cityServiceUpdateLoading: false,
  cityServiceUpdateError: action.cityServiceUpdateError,
});

const deleteCityServiceStart = (
  state: CityServiceStateType,
): CityServiceStateType => ({
  ...state,
  cityServiceDeleteLoading: true,
});

const deleteCityServiceSuccess = (
  state: CityServiceStateType,
): CityServiceStateType => ({
  ...state,
  cityServiceDeleteLoading: false,
  cityServiceDeleteError: null,
  cityServiceDeleteSuccess: true,
  cityServicesListUpdateNeeded: true,
});

const deleteCityServiceFail = (
  state: CityServiceStateType,
  action: CityServiceActionType,
): CityServiceStateType => ({
  ...state,
  cityServiceDeleteLoading: false,
  cityServiceDeleteError: action.cityServiceDeleteError,
});

const resetCityServiceStore = (
  state: CityServiceStateType,
): CityServiceStateType => ({
  ...initialState,
});

const completelyResetCityServiceStore = (): CityServiceStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CityServiceActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CITY_SERVICES_START:
      return fetchCityServicesStart(state);
    case actionTypes.FETCH_CITY_SERVICES_SUCCESS:
      return fetchCityServicesSuccess(state, action);
    case actionTypes.FETCH_CITY_SERVICES_FAIL:
      return fetchCityServicesFail(state, action);
    case actionTypes.CREATE_CITY_SERVICE_START:
      return createCityServiceStart(state);
    case actionTypes.CREATE_CITY_SERVICE_SUCCESS:
      return createCityServiceSuccess(state, action);
    case actionTypes.CREATE_CITY_SERVICE_FAIL:
      return createCityServiceFail(state, action);
    case actionTypes.UPDATE_CITY_SERVICE_START:
      return updateCityServiceStart(state);
    case actionTypes.UPDATE_CITY_SERVICE_SUCCESS:
      return updateCityServiceSuccess(state);
    case actionTypes.UPDATE_CITY_SERVICE_FAIL:
      return updateCityServiceFail(state, action);
    case actionTypes.DELETE_CITY_SERVICE_START:
      return deleteCityServiceStart(state);
    case actionTypes.DELETE_CITY_SERVICE_SUCCESS:
      return deleteCityServiceSuccess(state);
    case actionTypes.DELETE_CITY_SERVICE_FAIL:
      return deleteCityServiceFail(state, action);
    case actionTypes.RESET_CITY_SERVICE_STORE:
      return resetCityServiceStore(state);
    case actionTypes.LOGOUT:
      return completelyResetCityServiceStore();
    default:
      return state;
  }
};

export default reducer;
