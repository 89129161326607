export const FETCH_CITY_SERVICES_START = 'FETCH_CITY_SERVICES_START';
export const FETCH_CITY_SERVICES_SUCCESS = 'FETCH_CITY_SERVICES_SUCCESS';
export const FETCH_CITY_SERVICES_FAIL = 'FETCH_CITY_SERVICES_FAIL';

export const CREATE_CITY_SERVICE_START = 'CREATE_CITY_SERVICE_START';
export const CREATE_CITY_SERVICE_SUCCESS = 'CREATE_CITY_SERVICE_SUCCESS';
export const CREATE_CITY_SERVICE_FAIL = 'CREATE_CITY_SERVICE_FAIL';

export const UPDATE_CITY_SERVICE_START = 'UPDATE_CITY_SERVICE_START';
export const UPDATE_CITY_SERVICE_SUCCESS = 'UPDATE_CITY_SERVICE_SUCCESS';
export const UPDATE_CITY_SERVICE_FAIL = 'UPDATE_CITY_SERVICE_FAIL';

export const DELETE_CITY_SERVICE_START = 'DELETE_CITY_SERVICE_START';
export const DELETE_CITY_SERVICE_SUCCESS = 'DELETE_CITY_SERVICE_SUCCESS';
export const DELETE_CITY_SERVICE_FAIL = 'DELETE_CITY_SERVICE_FAIL';

export const RESET_CITY_SERVICE_STORE = 'RESET_CITY_SERVICE_STORE';

export const LOGOUT = 'LOGOUT';

export type CityServiceActionTypes =
  | typeof FETCH_CITY_SERVICES_START
  | typeof FETCH_CITY_SERVICES_SUCCESS
  | typeof FETCH_CITY_SERVICES_FAIL
  | typeof CREATE_CITY_SERVICE_START
  | typeof CREATE_CITY_SERVICE_SUCCESS
  | typeof CREATE_CITY_SERVICE_FAIL
  | typeof UPDATE_CITY_SERVICE_START
  | typeof UPDATE_CITY_SERVICE_SUCCESS
  | typeof UPDATE_CITY_SERVICE_FAIL
  | typeof DELETE_CITY_SERVICE_START
  | typeof DELETE_CITY_SERVICE_SUCCESS
  | typeof DELETE_CITY_SERVICE_FAIL
  | typeof RESET_CITY_SERVICE_STORE
  | typeof LOGOUT;
