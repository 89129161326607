export const FETCH_REVIEWS_START = 'FETCH_REVIEWS_START';
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAIL = 'FETCH_REVIEWS_FAIL';

export const FETCH_POPULAR_REVIEWS_START = 'FETCH_POPULAR_REVIEWS_START';
export const FETCH_POPULAR_REVIEWS_SUCCESS = 'FETCH_POPULAR_REVIEWS_SUCCESS';
export const FETCH_POPULAR_REVIEWS_FAIL = 'FETCH_POPULAR_REVIEWS_FAIL';

export const FETCH_REVIEW_START = 'FETCH_REVIEW_START';
export const FETCH_REVIEW_SUCCESS = 'FETCH_REVIEW_SUCCESS';
export const FETCH_REVIEW_FAIL = 'FETCH_REVIEW_FAIL';

export const CREATE_REVIEW_START = 'CREATE_REVIEW_START';
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_FAIL = 'CREATE_REVIEW_FAIL';

export const UPDATE_REVIEW_START = 'UPDATE_REVIEW_START';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_FAIL = 'UPDATE_REVIEW_FAIL';

export const DELETE_REVIEW_START = 'DELETE_REVIEW_START';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL';

export const RESET_CREATED_REVIEW = 'RESET_CREATED_REVIEW';

export const RESET_REVIEW_STORE = 'RESET_REVIEW_STORE';

export const LOGOUT = 'LOGOUT';

export type ReviewActionTypes =
  | typeof FETCH_REVIEWS_START
  | typeof FETCH_REVIEWS_SUCCESS
  | typeof FETCH_REVIEWS_FAIL
  | typeof FETCH_POPULAR_REVIEWS_START
  | typeof FETCH_POPULAR_REVIEWS_SUCCESS
  | typeof FETCH_POPULAR_REVIEWS_FAIL
  | typeof FETCH_REVIEW_START
  | typeof FETCH_REVIEW_SUCCESS
  | typeof FETCH_REVIEW_FAIL
  | typeof CREATE_REVIEW_START
  | typeof CREATE_REVIEW_SUCCESS
  | typeof CREATE_REVIEW_FAIL
  | typeof UPDATE_REVIEW_START
  | typeof UPDATE_REVIEW_SUCCESS
  | typeof UPDATE_REVIEW_FAIL
  | typeof DELETE_REVIEW_START
  | typeof DELETE_REVIEW_SUCCESS
  | typeof DELETE_REVIEW_FAIL
  | typeof RESET_CREATED_REVIEW
  | typeof RESET_REVIEW_STORE
  | typeof LOGOUT;
