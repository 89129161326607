export const FETCH_BASKETS_START = 'FETCH_BASKETS_START';
export const FETCH_BASKETS_SUCCESS = 'FETCH_BASKETS_SUCCESS';
export const FETCH_BASKETS_FAIL = 'FETCH_BASKETS_FAIL';

export const FETCH_BASKET_START = 'FETCH_BASKET_START';
export const FETCH_BASKET_SUCCESS = 'FETCH_BASKET_SUCCESS';
export const FETCH_BASKET_FAIL = 'FETCH_BASKET_FAIL';

export const CREATE_BASKET_START = 'CREATE_BASKET_START';
export const CREATE_BASKET_SUCCESS = 'CREATE_BASKET_SUCCESS';
export const CREATE_BASKET_FAIL = 'CREATE_BASKET_FAIL';

export const UPDATE_BASKET_START = 'UPDATE_BASKET_START';
export const UPDATE_BASKET_SUCCESS = 'UPDATE_BASKET_SUCCESS';
export const UPDATE_BASKET_FAIL = 'UPDATE_BASKET_FAIL';

export const DELETE_BASKET_START = 'DELETE_BASKET_START';
export const DELETE_BASKET_SUCCESS = 'DELETE_BASKET_SUCCESS';
export const DELETE_BASKET_FAIL = 'DELETE_BASKET_FAIL';

export const RESET_CREATED_BASKET = 'RESET_CREATED_BASKET';

export const RESET_BASKET_STORE = 'RESET_BASKET_STORE';

export const LOGOUT = 'LOGOUT';

export type BasketActionTypes =
  | typeof FETCH_BASKETS_START
  | typeof FETCH_BASKETS_SUCCESS
  | typeof FETCH_BASKETS_FAIL
  | typeof FETCH_BASKET_START
  | typeof FETCH_BASKET_SUCCESS
  | typeof FETCH_BASKET_FAIL
  | typeof CREATE_BASKET_START
  | typeof CREATE_BASKET_SUCCESS
  | typeof CREATE_BASKET_FAIL
  | typeof UPDATE_BASKET_START
  | typeof UPDATE_BASKET_SUCCESS
  | typeof UPDATE_BASKET_FAIL
  | typeof DELETE_BASKET_START
  | typeof DELETE_BASKET_SUCCESS
  | typeof DELETE_BASKET_FAIL
  | typeof RESET_CREATED_BASKET
  | typeof RESET_BASKET_STORE
  | typeof LOGOUT;
