import * as actionTypes from './actionTypes';
import { BasketActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Basket } from '../../domain/Basket';
import { ListResults } from '../../common/List/List';

export type BasketStateType = {
  basketsList: ListResults<Basket> | null;
  basketsLoading: boolean;
  basketsError: HttpError;
  basketsListUpdateNeeded: boolean;
  basketCreateLoading: boolean;
  basketCreateError: HttpError;
  basketCreateSuccess: boolean;
  createdBasket: Basket | null;
  basketUpdateLoading: boolean;
  basketUpdateError: HttpError;
  basketUpdateSuccess: boolean;
  updatedBasket: Basket | null;
  basketDeleteLoading: boolean;
  basketDeleteError: HttpError;
  basketDeleteSuccess: boolean;
  basket: Basket | null;
  basketLoading: boolean;
  basketError: HttpError;
};

export type BasketActionType = BasketStateType & {
  type: BasketActionTypes;
};

export const initialState: BasketStateType = {
  basketsList: null,
  basketsLoading: true,
  basketsError: null,
  basketsListUpdateNeeded: false,
  basketCreateLoading: false,
  basketCreateError: null,
  basketCreateSuccess: false,
  createdBasket: null,
  basketUpdateLoading: false,
  basketUpdateError: null,
  basketUpdateSuccess: false,
  updatedBasket: null,
  basketDeleteLoading: false,
  basketDeleteError: null,
  basketDeleteSuccess: false,
  basket: null,
  basketLoading: false,
  basketError: null,
};

const fetchBasketsStart = (state: BasketStateType): BasketStateType => ({
  ...state,
  basketsLoading: true,
});

const fetchBasketsSuccess = (
  state: BasketStateType,
  action: BasketActionType,
): BasketStateType => ({
  ...state,
  basketsList: action.basketsList,
  basketsLoading: false,
  basketsError: null,
  basketsListUpdateNeeded: false,
});

const fetchBasketsFail = (
  state: BasketStateType,
  action: BasketActionType,
): BasketStateType => ({
  ...state,
  basketsError: action.basketsError,
  basketsLoading: false,
});

const fetchBasketStart = (state: BasketStateType): BasketStateType => ({
  ...state,
  basketLoading: true,
  basketCreateSuccess: false,
  basketCreateError: null,
});

const fetchBasketSuccess = (
  state: BasketStateType,
  action: BasketActionType,
): BasketStateType => ({
  ...state,
  basket: action.basket,
  basketLoading: false,
  basketError: null,
});

const fetchBasketFail = (
  state: BasketStateType,
  action: BasketActionType,
): BasketStateType => ({
  ...state,
  basketError: action.basketError,
  basketLoading: false,
});

const createBasketStart = (state: BasketStateType): BasketStateType => ({
  ...state,
  basketCreateLoading: true,
});

const createBasketSuccess = (
  state: BasketStateType,
  action: BasketActionType,
): BasketStateType => {
  localStorage.setItem('basketId', action.createdBasket?.id ?? '');

  return {
    ...state,
    basketCreateLoading: false,
    basketCreateError: null,
    basketCreateSuccess: true,
    createdBasket: action.createdBasket,
  };
};

const createBasketFail = (
  state: BasketStateType,
  action: BasketActionType,
): BasketStateType => ({
  ...state,
  basketCreateLoading: false,
  basketCreateError: action.basketCreateError,
});

const updateBasketStart = (state: BasketStateType): BasketStateType => ({
  ...state,
  basketUpdateLoading: true,
  basketUpdateSuccess: false,
});

const updateBasketSuccess = (
  state: BasketStateType,
  action: BasketActionType,
): BasketStateType => {
  localStorage.setItem('basketId', action.updatedBasket?.id ?? '');

  return {
    ...state,
    basketUpdateLoading: false,
    basketUpdateError: null,
    basketUpdateSuccess: true,
    updatedBasket: action.updatedBasket,
  };
};

const updateBasketFail = (
  state: BasketStateType,
  action: BasketActionType,
): BasketStateType => ({
  ...state,
  basketUpdateLoading: false,
  basketUpdateError: action.basketUpdateError,
});

const deleteBasketStart = (state: BasketStateType): BasketStateType => ({
  ...state,
  basketDeleteLoading: true,
});

const deleteBasketSuccess = (state: BasketStateType): BasketStateType => ({
  ...state,
  basketDeleteLoading: false,
  basketDeleteError: null,
  basketDeleteSuccess: true,
  basketsListUpdateNeeded: true,
});

const deleteBasketFail = (
  state: BasketStateType,
  action: BasketActionType,
): BasketStateType => ({
  ...state,
  basketDeleteLoading: false,
  basketDeleteError: action.basketDeleteError,
});

const resetCreatedBasket = (state: BasketStateType): BasketStateType => ({
  ...state,
  createdBasket: null,
  basketCreateSuccess: false,
});

const resetBasketStore = (): BasketStateType => ({
  ...initialState,
});

const completelyResetBasketStore = (): BasketStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: BasketActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_BASKETS_START:
      return fetchBasketsStart(state);
    case actionTypes.FETCH_BASKETS_SUCCESS:
      return fetchBasketsSuccess(state, action);
    case actionTypes.FETCH_BASKETS_FAIL:
      return fetchBasketsFail(state, action);
    case actionTypes.FETCH_BASKET_START:
      return fetchBasketStart(state);
    case actionTypes.FETCH_BASKET_SUCCESS:
      return fetchBasketSuccess(state, action);
    case actionTypes.FETCH_BASKET_FAIL:
      return fetchBasketFail(state, action);
    case actionTypes.CREATE_BASKET_START:
      return createBasketStart(state);
    case actionTypes.CREATE_BASKET_SUCCESS:
      return createBasketSuccess(state, action);
    case actionTypes.CREATE_BASKET_FAIL:
      return createBasketFail(state, action);
    case actionTypes.UPDATE_BASKET_START:
      return updateBasketStart(state);
    case actionTypes.UPDATE_BASKET_SUCCESS:
      return updateBasketSuccess(state, action);
    case actionTypes.UPDATE_BASKET_FAIL:
      return updateBasketFail(state, action);
    case actionTypes.DELETE_BASKET_START:
      return deleteBasketStart(state);
    case actionTypes.DELETE_BASKET_SUCCESS:
      return deleteBasketSuccess(state);
    case actionTypes.DELETE_BASKET_FAIL:
      return deleteBasketFail(state, action);
    case actionTypes.RESET_CREATED_BASKET:
      return resetCreatedBasket(state);
    case actionTypes.RESET_BASKET_STORE:
      return resetBasketStore();
    case actionTypes.LOGOUT:
      return completelyResetBasketStore();
    default:
      return state;
  }
};

export default reducer;
