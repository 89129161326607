import { ListParams } from '../../hooks/useList/useList';
import axios from '../../config/Axios/axios-instance';
import {
  createUserFail,
  createUserStart,
  createUserSuccess,
  deleteUserFail,
  deleteUserStart,
  deleteUserSuccess,
  fetchCurrentUserFail,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  fetchUserFail,
  fetchUsersFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUserStart,
  fetchUserSuccess,
  updateUserFail,
  updateUserStart,
  updateUserSuccess,
  subscribeNewsletterFail,
  subscribeNewsletterSuccess,
  subscribeNewsletterStart,
} from './actions';
import { Dispatch } from 'redux';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { Role } from 'src/domain/Role';

export type UserCreateRequest = {
  email: string;
  role: Role;
};

export type UserUpdateRequest = {
  id: number;
  email: string;
  role: Role;
};

export type SubscribeNewsletterRequest = {
  email: string;
};

const API_URL = '/users';

export const fetchCurrentUser = () => (dispatch: Dispatch) => {
  dispatch(fetchCurrentUserStart());

  return axios
    .get(`${API_URL}/me`)
    .then((response) => {
      dispatch(fetchCurrentUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCurrentUserFail());
    });
};

export const fetchUser = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchUserStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserFail(err?.response?.data?.error));
    });
};

export const fetchUsers = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchUsersStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUsersFail(err?.response?.data?.error));
    });
};

export const createUser =
  (inputs: UserCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createUserStart());
    return axios
      .post(API_URL, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createUserSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.USER_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createUserFail(err?.response?.data?.message));
      });
  };

export const subscribeNewsletter =
  (inputs: SubscribeNewsletterRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(subscribeNewsletterStart());
    return axios
      .post(`${API_URL}/subscribe`, {
        ...inputs,
      })
      .then(() => {
        dispatch(subscribeNewsletterSuccess());
        showToast(translate(intl, 'SUCCESS.NEWSLETTER_SUBSCRIBE'), 'success');
      })
      .catch((err) => {
        dispatch(subscribeNewsletterFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'success');
      });
  };

export const updateUser =
  (inputs: UserUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateUserStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, {
        ...inputs,
      })
      .then(() => {
        dispatch(updateUserSuccess());
        showToast(translate(intl, 'SUCCESS.USER_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserFail(err?.response?.data?.message));
      });
  };

export const deleteUser =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteUserStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteUserSuccess());
        showToast(translate(intl, 'SUCCESS.USER_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteUserFail(err?.response?.data?.message));
      });
  };
