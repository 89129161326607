import * as actionTypes from './actionTypes';
import { ListResults } from '../../common/List/List';
import { HttpError } from '../../config/Axios/axios-instance';
import { Blog } from '../../domain/Blog';
import { BlogActionTypes } from './actionTypes';

export type BlogStateType = {
  blogsList: ListResults<Blog> | null;
  blogsLoading: boolean;
  blogsError: HttpError;
  blogsListUpdateNeeded: boolean;
  blog: Blog | null;
  blogLoading: boolean;
  blogError: HttpError;
  blogCreateLoading: boolean;
  blogCreateError: HttpError;
  blogCreateSuccess: boolean;
  createdBlog: Blog | null;
  blogUpdateLoading: boolean;
  blogUpdateError: HttpError;
  blogUpdateSuccess: boolean;
  blogDeleteLoading: boolean;
  blogDeleteError: HttpError;
  blogDeleteSuccess: boolean;
  tags: string[] | null;
  tagsLoading: boolean;
  tagsError: HttpError;
  tagsUpdateNeeded: boolean;
  publicBlogsList: ListResults<Blog> | null;
  publicBlogsLoading: boolean;
  publicBlogsError: HttpError;
  publicBlog: Blog | null;
  publicBlogLoading: boolean;
  publicBlogError: HttpError;
  relatedPublicBlogPosts: Blog[] | null;
  relatedPublicBlogPostsError: HttpError;
  relatedPublicBlogPostsLoading: boolean;
};

export type BlogActionType = BlogStateType & {
  type: BlogActionTypes;
};

export const initialState: BlogStateType = {
  blogsList: null,
  blogsLoading: true,
  blogsError: null,
  blogsListUpdateNeeded: false,
  blog: null,
  blogLoading: false,
  blogError: null,
  blogCreateLoading: false,
  blogCreateError: null,
  blogCreateSuccess: false,
  createdBlog: null,
  blogUpdateLoading: false,
  blogUpdateError: null,
  blogUpdateSuccess: false,
  blogDeleteLoading: false,
  blogDeleteError: null,
  blogDeleteSuccess: false,
  tags: null,
  tagsLoading: false,
  tagsError: null,
  tagsUpdateNeeded: false,
  publicBlogsError: null,
  publicBlogsLoading: false,
  publicBlogsList: null,
  publicBlog: null,
  publicBlogLoading: false,
  publicBlogError: null,
  relatedPublicBlogPosts: null,
  relatedPublicBlogPostsError: null,
  relatedPublicBlogPostsLoading: false,
};

const fetchBlogsStart = (state: BlogStateType): BlogStateType => ({
  ...state,
  blogsLoading: true,
});

const fetchBlogsSuccess = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  blogsList: action.blogsList,
  blogsLoading: false,
  blogsError: null,
  blogsListUpdateNeeded: false,
});

const fetchBlogsFail = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  blogsError: action.blogsError,
  blogsLoading: false,
});

const fetchPublicBlogsStart = (state: BlogStateType): BlogStateType => ({
  ...state,
  publicBlogsLoading: true,
});

const fetchPublicBlogsSuccess = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  publicBlogsList: action.publicBlogsList,
  publicBlogsLoading: false,
  publicBlogsError: null,
});

const fetchPublicBlogsFail = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  publicBlogsError: action.publicBlogsError,
  publicBlogsLoading: false,
});

const fetchBlogStart = (state: BlogStateType): BlogStateType => ({
  ...state,
  blogLoading: true,
  blogCreateSuccess: false,
  blogCreateError: null,
});

const fetchBlogSuccess = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  blog: action.blog,
  blogLoading: false,
  blogError: null,
});

const fetchBlogFail = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  blogError: action.blogError,
  blogLoading: false,
});

const fetchPublicBlogStart = (state: BlogStateType): BlogStateType => ({
  ...state,
  publicBlogLoading: true,
});

const fetchPublicBlogSuccess = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  publicBlog: action.publicBlog,
  publicBlogLoading: false,
  publicBlogError: null,
});

const fetchPublicBlogFail = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  publicBlogError: action.publicBlogError,
  publicBlogLoading: false,
});

const fetchRelatedPublicBlogsStart = (state: BlogStateType): BlogStateType => ({
  ...state,
  relatedPublicBlogPostsLoading: true,
});

const fetchRelatedPublicBlogsSuccess = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  relatedPublicBlogPosts: action.relatedPublicBlogPosts,
  relatedPublicBlogPostsLoading: false,
  relatedPublicBlogPostsError: null,
});

const fetchRelatedPublicBlogsFail = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  relatedPublicBlogPostsError: action.relatedPublicBlogPostsError,
  relatedPublicBlogPostsLoading: false,
});

const createBlogStart = (state: BlogStateType): BlogStateType => ({
  ...state,
  blogCreateLoading: true,
});

const createBlogSuccess = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  blogCreateLoading: false,
  blogCreateError: null,
  blogCreateSuccess: true,
  createdBlog: action.createdBlog,
});

const createBlogFail = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  blogCreateLoading: false,
  blogCreateError: action.blogCreateError,
});

const updateBlogStart = (state: BlogStateType): BlogStateType => ({
  ...state,
  blogUpdateLoading: true,
  blogUpdateSuccess: false,
});

const updateBlogSuccess = (state: BlogStateType): BlogStateType => ({
  ...state,
  blogUpdateLoading: false,
  blogUpdateError: null,
  blogUpdateSuccess: true,
});

const updateBlogFail = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  blogUpdateLoading: false,
  blogUpdateError: action.blogUpdateError,
});

const deleteBlogStart = (state: BlogStateType): BlogStateType => ({
  ...state,
  blogDeleteLoading: true,
});

const deleteBlogSuccess = (state: BlogStateType): BlogStateType => ({
  ...state,
  blogDeleteLoading: false,
  blogDeleteError: null,
  blogDeleteSuccess: true,
  blogsListUpdateNeeded: true,
});

const deleteBlogFail = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  blogDeleteLoading: false,
  blogDeleteError: action.blogDeleteError,
});

const fetchTagsStart = (state: BlogStateType): BlogStateType => ({
  ...state,
  tagsLoading: true,
});

const fetchTagsSuccess = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  tags: action.tags,
  tagsLoading: false,
  tagsError: null,
  tagsUpdateNeeded: false,
});

const fetchTagsFail = (
  state: BlogStateType,
  action: BlogActionType,
): BlogStateType => ({
  ...state,
  tagsError: action.tagsError,
  tagsLoading: false,
});

const resetBlogStore = (): BlogStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: BlogActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_BLOGS_START:
      return fetchBlogsStart(state);
    case actionTypes.FETCH_BLOGS_SUCCESS:
      return fetchBlogsSuccess(state, action);
    case actionTypes.FETCH_BLOGS_FAIL:
      return fetchBlogsFail(state, action);
    case actionTypes.FETCH_PUBLIC_BLOGS_START:
      return fetchPublicBlogsStart(state);
    case actionTypes.FETCH_PUBLIC_BLOGS_SUCCESS:
      return fetchPublicBlogsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_BLOGS_FAIL:
      return fetchPublicBlogsFail(state, action);
    case actionTypes.FETCH_BLOG_START:
      return fetchBlogStart(state);
    case actionTypes.FETCH_BLOG_SUCCESS:
      return fetchBlogSuccess(state, action);
    case actionTypes.FETCH_BLOG_FAIL:
      return fetchBlogFail(state, action);
    case actionTypes.FETCH_PUBLIC_BLOG_START:
      return fetchPublicBlogStart(state);
    case actionTypes.FETCH_PUBLIC_BLOG_SUCCESS:
      return fetchPublicBlogSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_BLOG_FAIL:
      return fetchPublicBlogFail(state, action);
    case actionTypes.CREATE_BLOG_START:
      return createBlogStart(state);
    case actionTypes.CREATE_BLOG_SUCCESS:
      return createBlogSuccess(state, action);
    case actionTypes.CREATE_BLOG_FAIL:
      return createBlogFail(state, action);
    case actionTypes.UPDATE_BLOG_START:
      return updateBlogStart(state);
    case actionTypes.UPDATE_BLOG_SUCCESS:
      return updateBlogSuccess(state);
    case actionTypes.UPDATE_BLOG_FAIL:
      return updateBlogFail(state, action);
    case actionTypes.DELETE_BLOG_START:
      return deleteBlogStart(state);
    case actionTypes.DELETE_BLOG_SUCCESS:
      return deleteBlogSuccess(state);
    case actionTypes.DELETE_BLOG_FAIL:
      return deleteBlogFail(state, action);
    case actionTypes.FETCH_TAGS_START:
      return fetchTagsStart(state);
    case actionTypes.FETCH_TAGS_SUCCESS:
      return fetchTagsSuccess(state, action);
    case actionTypes.FETCH_TAGS_FAIL:
      return fetchTagsFail(state, action);
    case actionTypes.FETCH_RELATED_BLOGS_START:
      return fetchRelatedPublicBlogsStart(state);
    case actionTypes.FETCH_RELATED_BLOGS_SUCCESS:
      return fetchRelatedPublicBlogsSuccess(state, action);
    case actionTypes.FETCH_RELATED_BLOGS_FAIL:
      return fetchRelatedPublicBlogsFail(state, action);
    case actionTypes.RESET_BLOG_STORE:
    case actionTypes.LOGOUT:
      return resetBlogStore();
    default:
      return state;
  }
};

export default reducer;
