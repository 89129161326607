import * as actionTypes from './actionTypes';
import { PeriodActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Period } from '../../domain/Period';

export type PeriodStateType = {
  periodCreateLoading: boolean;
  periodCreateError: HttpError;
  periodCreateSuccess: boolean;
  createdPeriod: Period | null;
  periodUpdateLoading: boolean;
  periodUpdateError: HttpError;
  periodUpdateSuccess: boolean;
  updatedPeriod: Period | null;
  periodDeleteLoading: boolean;
  periodDeleteError: HttpError;
  periodDeleteSuccess: boolean;
};

export type PeriodActionType = PeriodStateType & {
  type: PeriodActionTypes;
};

export const initialState: PeriodStateType = {
  periodCreateLoading: false,
  periodCreateError: null,
  periodCreateSuccess: false,
  createdPeriod: null,
  periodUpdateLoading: false,
  periodUpdateError: null,
  periodUpdateSuccess: false,
  updatedPeriod: null,
  periodDeleteLoading: false,
  periodDeleteError: null,
  periodDeleteSuccess: false,
};

const createPeriodStart = (state: PeriodStateType): PeriodStateType => ({
  ...state,
  periodCreateLoading: true,
});

const createPeriodSuccess = (
  state: PeriodStateType,
  action: PeriodActionType,
): PeriodStateType => ({
  ...state,
  periodCreateLoading: false,
  periodCreateError: null,
  periodCreateSuccess: true,
  createdPeriod: action.createdPeriod,
});

const createPeriodFail = (
  state: PeriodStateType,
  action: PeriodActionType,
): PeriodStateType => ({
  ...state,
  periodCreateLoading: false,
  periodCreateError: action.periodCreateError,
});

const updatePeriodStart = (state: PeriodStateType): PeriodStateType => ({
  ...state,
  periodUpdateLoading: true,
});

const updatePeriodSuccess = (
  state: PeriodStateType,
  action: PeriodActionType,
): PeriodStateType => ({
  ...state,
  periodUpdateLoading: false,
  periodUpdateError: null,
  periodUpdateSuccess: true,
  updatedPeriod: action.updatedPeriod,
});

const updatePeriodFail = (
  state: PeriodStateType,
  action: PeriodActionType,
): PeriodStateType => ({
  ...state,
  periodUpdateLoading: false,
  periodUpdateError: action.periodUpdateError,
});

const deletePeriodStart = (state: PeriodStateType): PeriodStateType => ({
  ...state,
  periodDeleteLoading: true,
  periodDeleteSuccess: false,
});

const deletePeriodSuccess = (state: PeriodStateType): PeriodStateType => ({
  ...state,
  periodDeleteLoading: false,
  periodDeleteError: null,
  periodDeleteSuccess: true,
});

const deletePeriodFail = (
  state: PeriodStateType,
  action: PeriodActionType,
): PeriodStateType => ({
  ...state,
  periodDeleteLoading: false,
  periodDeleteError: action.periodDeleteError,
});

const resetCreatedPeriod = (state: PeriodStateType): PeriodStateType => ({
  ...state,
  createdPeriod: null,
});

const resetUpdatedPeriod = (state: PeriodStateType): PeriodStateType => ({
  ...state,
  updatedPeriod: null,
});

const resetDeletedPeriod = (state: PeriodStateType): PeriodStateType => ({
  ...state,
  periodDeleteLoading: false,
  periodDeleteError: null,
  periodDeleteSuccess: false,
});

const resetPeriodStore = (): PeriodStateType => ({
  ...initialState,
});

const completelyResetPeriodStore = (): PeriodStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PeriodActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_PERIOD_START:
      return createPeriodStart(state);
    case actionTypes.CREATE_PERIOD_SUCCESS:
      return createPeriodSuccess(state, action);
    case actionTypes.CREATE_PERIOD_FAIL:
      return createPeriodFail(state, action);
    case actionTypes.UPDATE_PERIOD_START:
      return updatePeriodStart(state);
    case actionTypes.UPDATE_PERIOD_SUCCESS:
      return updatePeriodSuccess(state, action);
    case actionTypes.UPDATE_PERIOD_FAIL:
      return updatePeriodFail(state, action);
    case actionTypes.DELETE_PERIOD_START:
      return deletePeriodStart(state);
    case actionTypes.DELETE_PERIOD_SUCCESS:
      return deletePeriodSuccess(state);
    case actionTypes.DELETE_PERIOD_FAIL:
      return deletePeriodFail(state, action);
    case actionTypes.RESET_CREATED_PERIOD:
      return resetCreatedPeriod(state);
    case actionTypes.RESET_UPDATED_PERIOD:
      return resetUpdatedPeriod(state);
    case actionTypes.RESET_DELETED_PERIOD:
      return resetDeletedPeriod(state);
    case actionTypes.RESET_PERIOD_STORE:
      return resetPeriodStore();
    case actionTypes.LOGOUT:
      return completelyResetPeriodStore();
    default:
      return state;
  }
};

export default reducer;
