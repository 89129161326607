import React from 'react';
import styles from './PublicNavigation.module.scss';
import {
  faBars,
  faChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { Link, NavLink } from 'react-router-dom';
import { routes } from '../../config/Router/routes';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/logo.svg';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { TopNavigationItem } from '../Layout/Layout';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Locale } from '../../domain/Translation';
import { connect } from 'react-redux';
import { LocationsSearchParams } from '../../store/location/service';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { setLocationsSearchParams } from '../../store/location/actions';
import MobilePublicNavigation from './MobilePublicNavigation/MobilePublicNavigation';
import phone from '../../assets/icons/phone.svg';
import email from '../../assets/icons/email.svg';
import viber from '../../assets/icons/viber.webp';
import whatsapp from '../../assets/icons/whatsapp.webp';
import { IconButton } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { RemoveScroll } from 'react-remove-scroll';

export type Props = {
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
  navigationItems: TopNavigationItem[];
  selectedLocale: Locale;
  onSetLocationsSearchParams: (
    searchLocationsParams: LocationsSearchParams,
  ) => void;
  searchLocationsParams: LocationsSearchParams | null;
};

const MOBILE_BREAK_POINT = 900;

export const PublicNavigation = ({
  onDrawerClick,
  isMobileMenuOpen,
  navigationItems,
  selectedLocale,
  onSetLocationsSearchParams,
  searchLocationsParams,
}: Props) => {
  const { width } = useWindowSize();
  const intl = useIntl();

  const handleNavigationLinkClick = (label: string) => {
    onSetLocationsSearchParams({
      ...searchLocationsParams,
    });
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'Navigation Link Click',
      eventProps: {
        category: 'Link Top Navigation',
        action: 'Click',
        label: label,
      },
    });
  };

  return (
    <>
      <div className={styles.topPart}>
        <a
          href={`tel:${translate(intl, 'PUBLIC_NAVIGATION.SOCIAL_PHONE')}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <ReactSVG src={phone} className={styles.icon} />
          <span className={styles.phone}>
            {translate(intl, 'PUBLIC_NAVIGATION.SOCIAL_PHONE')}
          </span>
        </a>
        <a
          href={`mailto:${translate(intl, 'PUBLIC_NAVIGATION.SOCIAL_EMAIL')}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <ReactSVG src={email} className={styles.icon} />
          <span>{translate(intl, 'PUBLIC_NAVIGATION.SOCIAL_EMAIL')}</span>
        </a>
        <a href={`${translate(intl, 'PUBLIC_NAVIGATION.SOCIAL_VIBER')}`}>
          <img src={viber} alt="Viber logo" />
        </a>
        <a href={`${translate(intl, 'PUBLIC_NAVIGATION.SOCIAL_WHATSAPP')}`}>
          <img src={whatsapp} alt="Whatsapp logo" />
        </a>

        <LanguageSwitcher />
      </div>

      <div className={styles.bottomPart}>
        <div className={styles.logo}>
          <Link to={getLocaleUrl(routes.homepage, selectedLocale)}>
            <ReactSVG src={logo} />
          </Link>
        </div>
        {width && width >= MOBILE_BREAK_POINT && (
          <div className={styles.navigationContainer}>
            <ul className={styles.navigationMenu}>
              {navigationItems.map((item) => (
                <li key={item.label}>
                  {item.to ? (
                    <Link
                      to={item.to}
                      onClick={() => handleNavigationLinkClick(item.label)}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    <p>{item.label}</p>
                  )}

                  {(item.locationGroups || item.locationsWithoutGroup) && (
                    <ul className={styles.subMenu}>
                      {item.locationGroups?.map((locationGroup) => (
                        <li key={`${locationGroup.label}-${item.label}`}>
                          <Link
                            to={locationGroup.to}
                            onClick={() =>
                              handleNavigationLinkClick(locationGroup.label)
                            }
                          >
                            {locationGroup.label}
                            <FontAwesomeIcon
                              className={styles.rightIcon}
                              icon={faChevronRight}
                            />
                          </Link>

                          {locationGroup.locations && (
                            <ul className={styles.subMenuSecond}>
                              {locationGroup.locations.map((location) => (
                                <li key={location.label}>
                                  <Link
                                    to={location.to}
                                    onClick={() =>
                                      handleNavigationLinkClick(location.label)
                                    }
                                  >
                                    {location.label}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}

                      {item.locationsWithoutGroup?.map((location) => (
                        <li key={`${location.label}-${item.label}`}>
                          <Link
                            to={location.to}
                            onClick={() =>
                              handleNavigationLinkClick(location.label)
                            }
                          >
                            {location.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
        {width && width < MOBILE_BREAK_POINT && (
          <IconButton onClick={() => onDrawerClick()}>
            <FontAwesomeIcon
              className={styles.drawerIcon}
              icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
              fixedWidth
              size="sm"
            />
          </IconButton>
        )}

        {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
          <RemoveScroll forwardProps>
            <div className={styles.mobileMenu}>
              {navigationItems.map((item, index) => (
                <React.Fragment key={`MobileNavigationItem-${index}`}>
                  {item?.locationGroups?.length ||
                  item?.locationsWithoutGroup?.length ? (
                    <MobilePublicNavigation
                      topNavigationItem={item}
                      handleNavigationLinkClick={handleNavigationLinkClick}
                    />
                  ) : item.to ? (
                    <NavLink
                      key={item.label}
                      to={item.to}
                      className={({ isActive }) =>
                        cx(styles.navigationItem, {
                          [styles.activeSubItem]: isActive,
                        })
                      }
                      onClick={() => handleNavigationLinkClick(item.label)}
                    >
                      {item.label}
                    </NavLink>
                  ) : (
                    <p key={item.label} className={styles.navigationItem}>
                      {item.label}
                    </p>
                  )}
                </React.Fragment>
              ))}
            </div>
          </RemoveScroll>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  searchLocationsParams: state.location.searchLocationsParams,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSetLocationsSearchParams: (searchLocationsParams: LocationsSearchParams) =>
    dispatch(setLocationsSearchParams(searchLocationsParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicNavigation);
