import * as actionTypes from './actionTypes';
import { SpecialOfferActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { SpecialOffer } from '../../domain/SpecialOffer';
import { ListResults } from '../../common/List/List';

export type SpecialOfferStateType = {
  specialOfferList: ListResults<SpecialOffer> | null;
  specialOfferLoading: boolean;
  specialOfferError: HttpError;
  specialOfferListUpdateNeeded: boolean;
  specialOfferCreateLoading: boolean;
  specialOfferCreateError: HttpError;
  specialOfferCreateSuccess: boolean;
  createdSpecialOffer: SpecialOffer | null;
  specialOfferUpdateLoading: boolean;
  specialOfferUpdateError: HttpError;
  specialOfferUpdateSuccess: boolean;
  specialOfferDeleteLoading: boolean;
  specialOfferDeleteError: HttpError;
  specialOfferDeleteSuccess: boolean;
  specialOffer: SpecialOffer | null;
};

export type SpecialOfferActionType = SpecialOfferStateType & {
  type: SpecialOfferActionTypes;
};

export const initialState: SpecialOfferStateType = {
  specialOfferList: null,
  specialOfferLoading: true,
  specialOfferError: null,
  specialOfferListUpdateNeeded: false,
  specialOfferCreateLoading: false,
  specialOfferCreateError: null,
  specialOfferCreateSuccess: false,
  createdSpecialOffer: null,
  specialOfferUpdateLoading: false,
  specialOfferUpdateError: null,
  specialOfferUpdateSuccess: false,
  specialOfferDeleteLoading: false,
  specialOfferDeleteError: null,
  specialOfferDeleteSuccess: false,
  specialOffer: null,
};

const fetchEnquiriesStart = (
  state: SpecialOfferStateType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferLoading: true,
});

const fetchEnquiriesSuccess = (
  state: SpecialOfferStateType,
  action: SpecialOfferActionType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferList: action.specialOfferList,
  specialOfferLoading: false,
  specialOfferError: null,
  specialOfferListUpdateNeeded: false,
});

const fetchEnquiriesFail = (
  state: SpecialOfferStateType,
  action: SpecialOfferActionType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferError: action.specialOfferError,
  specialOfferLoading: false,
});

const createSpecialOfferStart = (
  state: SpecialOfferStateType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferCreateLoading: true,
});

const createSpecialOfferSuccess = (
  state: SpecialOfferStateType,
  action: SpecialOfferActionType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferCreateLoading: false,
  specialOfferCreateError: null,
  specialOfferCreateSuccess: true,
  createdSpecialOffer: action.createdSpecialOffer,
  specialOfferListUpdateNeeded: true,
});

const createSpecialOfferFail = (
  state: SpecialOfferStateType,
  action: SpecialOfferActionType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferCreateLoading: false,
  specialOfferCreateError: action.specialOfferCreateError,
});

const updateSpecialOfferStart = (
  state: SpecialOfferStateType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferUpdateLoading: true,
  specialOfferUpdateSuccess: false,
});

const updateSpecialOfferSuccess = (
  state: SpecialOfferStateType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferUpdateLoading: false,
  specialOfferUpdateError: null,
  specialOfferUpdateSuccess: true,
  specialOfferListUpdateNeeded: true,
});

const updateSpecialOfferFail = (
  state: SpecialOfferStateType,
  action: SpecialOfferActionType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferUpdateLoading: false,
  specialOfferUpdateError: action.specialOfferUpdateError,
});

const deleteSpecialOfferStart = (
  state: SpecialOfferStateType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferDeleteLoading: true,
});

const deleteSpecialOfferSuccess = (
  state: SpecialOfferStateType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferDeleteLoading: false,
  specialOfferDeleteError: null,
  specialOfferDeleteSuccess: true,
  specialOfferListUpdateNeeded: true,
});

const deleteSpecialOfferFail = (
  state: SpecialOfferStateType,
  action: SpecialOfferActionType,
): SpecialOfferStateType => ({
  ...state,
  specialOfferDeleteLoading: false,
  specialOfferDeleteError: action.specialOfferDeleteError,
});

const resetCreatedSpecialOffer = (
  state: SpecialOfferStateType,
): SpecialOfferStateType => ({
  ...state,
  createdSpecialOffer: null,
  specialOfferCreateSuccess: false,
});

const resetSpecialOfferStore = (): SpecialOfferStateType => ({
  ...initialState,
});

const completelyResetSpecialOfferStore = (): SpecialOfferStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: SpecialOfferActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SPECIAL_OFFERS_START:
      return fetchEnquiriesStart(state);
    case actionTypes.FETCH_SPECIAL_OFFERS_SUCCESS:
      return fetchEnquiriesSuccess(state, action);
    case actionTypes.FETCH_SPECIAL_OFFERS_FAIL:
      return fetchEnquiriesFail(state, action);
    case actionTypes.CREATE_SPECIAL_OFFER_START:
      return createSpecialOfferStart(state);
    case actionTypes.CREATE_SPECIAL_OFFER_SUCCESS:
      return createSpecialOfferSuccess(state, action);
    case actionTypes.CREATE_SPECIAL_OFFER_FAIL:
      return createSpecialOfferFail(state, action);
    case actionTypes.UPDATE_SPECIAL_OFFER_START:
      return updateSpecialOfferStart(state);
    case actionTypes.UPDATE_SPECIAL_OFFER_SUCCESS:
      return updateSpecialOfferSuccess(state);
    case actionTypes.UPDATE_SPECIAL_OFFER_FAIL:
      return updateSpecialOfferFail(state, action);
    case actionTypes.DELETE_SPECIAL_OFFER_START:
      return deleteSpecialOfferStart(state);
    case actionTypes.DELETE_SPECIAL_OFFER_SUCCESS:
      return deleteSpecialOfferSuccess(state);
    case actionTypes.DELETE_SPECIAL_OFFER_FAIL:
      return deleteSpecialOfferFail(state, action);
    case actionTypes.RESET_CREATED_SPECIAL_OFFER:
      return resetCreatedSpecialOffer(state);
    case actionTypes.RESET_SPECIAL_OFFER_STORE:
      return resetSpecialOfferStore();
    case actionTypes.LOGOUT:
      return completelyResetSpecialOfferStore();
    default:
      return state;
  }
};

export default reducer;
