export const FETCH_SPECIAL_OFFERS_START = 'FETCH_SPECIAL_OFFERS_START';
export const FETCH_SPECIAL_OFFERS_SUCCESS = 'FETCH_SPECIAL_OFFERS_SUCCESS';
export const FETCH_SPECIAL_OFFERS_FAIL = 'FETCH_SPECIAL_OFFERS_FAIL';

export const CREATE_SPECIAL_OFFER_START = 'CREATE_SPECIAL_OFFER_START';
export const CREATE_SPECIAL_OFFER_SUCCESS = 'CREATE_SPECIAL_OFFER_SUCCESS';
export const CREATE_SPECIAL_OFFER_FAIL = 'CREATE_SPECIAL_OFFER_FAIL';

export const UPDATE_SPECIAL_OFFER_START = 'UPDATE_SPECIAL_OFFER_START';
export const UPDATE_SPECIAL_OFFER_SUCCESS = 'UPDATE_SPECIAL_OFFER_SUCCESS';
export const UPDATE_SPECIAL_OFFER_FAIL = 'UPDATE_SPECIAL_OFFER_FAIL';

export const DELETE_SPECIAL_OFFER_START = 'DELETE_SPECIAL_OFFER_START';
export const DELETE_SPECIAL_OFFER_SUCCESS = 'DELETE_SPECIAL_OFFER_SUCCESS';
export const DELETE_SPECIAL_OFFER_FAIL = 'DELETE_SPECIAL_OFFER_FAIL';

export const RESET_CREATED_SPECIAL_OFFER = 'RESET_CREATED_SPECIAL_OFFER';

export const RESET_SPECIAL_OFFER_STORE = 'RESET_SPECIAL_OFFER_STORE';

export const LOGOUT = 'LOGOUT';

export type SpecialOfferActionTypes =
  | typeof FETCH_SPECIAL_OFFERS_START
  | typeof FETCH_SPECIAL_OFFERS_SUCCESS
  | typeof FETCH_SPECIAL_OFFERS_FAIL
  | typeof CREATE_SPECIAL_OFFER_START
  | typeof CREATE_SPECIAL_OFFER_SUCCESS
  | typeof CREATE_SPECIAL_OFFER_FAIL
  | typeof UPDATE_SPECIAL_OFFER_START
  | typeof UPDATE_SPECIAL_OFFER_SUCCESS
  | typeof UPDATE_SPECIAL_OFFER_FAIL
  | typeof DELETE_SPECIAL_OFFER_START
  | typeof DELETE_SPECIAL_OFFER_SUCCESS
  | typeof DELETE_SPECIAL_OFFER_FAIL
  | typeof RESET_CREATED_SPECIAL_OFFER
  | typeof RESET_SPECIAL_OFFER_STORE
  | typeof LOGOUT;
