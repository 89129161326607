import React, { ReactNode, useState, useEffect } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import { routes } from '../../config/Router/routes';
import {
  faChildren,
  faHouseChimney,
  faPersonShelter,
  faRectangleList,
  faTreeCity,
  faBath,
  faCircleQuestion,
  faGlobe,
  faLayerGroup,
  faUser,
  faPen,
  faComment,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal/Modal';
import CurrentAccount from '../CurrentAccount/CurrentAccount';
import { Location } from '../../domain/Location';
import * as locationService from '../../store/location/service';
import { ThunkDispatch } from 'redux-thunk';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import LocationSelectForm from '../../component/Location/LocationSelectForm/LocationSelectForm';
import PublicNavigation from '../PublicNavigation/PublicNavigation';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import Footer from '../Footer/Footer';
import {
  ListParams,
  MAX_LIST_PARAMS,
  SORT_DESC,
} from '../../hooks/useList/useList';
import * as cityService from '../../store/city/service';
import * as locationGroupService from '../../store/locationGroup/service';
import { ListResults } from '../List/List';
import { City } from '../../domain/City';
import { Locale } from '../../domain/Translation';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { getTranslationByKey } from '../../utility/domainTranslation/translator';
import { Language } from '../../domain/Language';
import { LocationGroup } from '../../domain/LocationGroup';
import { GetPublicTopNavigationItemsResponse } from '../../domain/PublicTopNavigationItem';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  locationOptions: Location[];
  onLocationOptionsFetch: () => void;
  selectedLocation: number | null;
  onCitiesFetch: (listParams: ListParams) => void;
  citiesList: ListResults<City> | null;
  onPublicTopNavigationItemsFetch: () => void;
  publicTopNavigationItems: GetPublicTopNavigationItemsResponse;
  onLocationGroupsFetch: (listParams: ListParams) => void;
  locationGroupsList: ListResults<LocationGroup> | null;
  selectedLocale: Locale;
  languages: Language[];
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: IconProp;
};

type TopNavigationItemLocationGroup = {
  to: string;
  label: string;
  locations: NavigationItem[];
};

export type TopNavigationItem = {
  label: string;
  to?: string;
  item?: NavigationItem[];
  locationGroups?: TopNavigationItemLocationGroup[];
  locationsWithoutGroup?: NavigationItem[];
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  isAuthenticated,
  locationOptions,
  onLocationOptionsFetch,
  selectedLocation,
  onCitiesFetch,
  citiesList,
  onPublicTopNavigationItemsFetch,
  publicTopNavigationItems,
  onLocationGroupsFetch,
  locationGroupsList,
  selectedLocale,
  languages,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [location, setLocation] = useState<Location | undefined>(undefined);
  const routeLocation = useLocation();
  const intl = useIntl();

  useEffect(() => {
    if (!citiesList) {
      onCitiesFetch({
        ...MAX_LIST_PARAMS,
        sortBy: 'city.name',
        sortDirection: SORT_DESC,
      });
    }
  }, []);

  useEffect(() => {
    if (!locationGroupsList) {
      onLocationGroupsFetch({
        ...MAX_LIST_PARAMS,
        sortBy: 'locationGroup.name',
        sortDirection: SORT_DESC,
      });
    }
  }, []);

  useEffect(() => {
    onPublicTopNavigationItemsFetch();
  }, [isAuthenticated]);

  const { width } = useWindowSize();

  const getTopNavigationItems = () => {
    const { menuItems, foreignCities } = publicTopNavigationItems;

    if (!menuItems) {
      return [];
    }

    return [
      ...menuItems.map((city) => ({
        label:
          getTranslationByKey(
            'city',
            city.id,
            selectedLocale,
            'name',
            languages,
          ) ?? city.name,
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          city.seo?.slug ?? '',
        ),
        locationGroups: city?.locationGroups.map((locationGroup) => ({
          label:
            getTranslationByKey(
              'locationGroup',
              locationGroup.id,
              selectedLocale,
              'name',
              languages,
            ) ?? locationGroup.name,
          to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
            ':slug',
            locationGroup.seo?.slug ?? '',
          ),
          locations: locationGroup?.locations.map((location) => {
            return {
              label:
                getTranslationByKey(
                  'location',
                  location.id,
                  selectedLocale,
                  'name',
                  languages,
                ) ?? location.name,
              to: getLocaleUrl(routes.locationPage, selectedLocale)
                .replace(':city', city.seo?.slug ?? '')
                .replace(
                  ':location',
                  getTranslationByKey(
                    'seo',
                    location.seo.id,
                    selectedLocale,
                    'seoSlug',
                    languages,
                  ) ??
                    location.seo?.slug ??
                    '',
                ),
            };
          }),
        })),
        locationsWithoutGroup: city?.locationsWithoutGroup.map((location) => {
          return {
            label:
              getTranslationByKey(
                'location',
                location.id,
                selectedLocale,
                'name',
                languages,
              ) ?? location.name,
            to: getLocaleUrl(routes.locationPage, selectedLocale)
              .replace(':city', city.seo?.slug ?? '')
              .replace(
                ':location',
                getTranslationByKey(
                  'seo',
                  location.seo.id,
                  selectedLocale,
                  'seoSlug',
                  languages,
                ) ??
                  location.seo?.slug ??
                  '',
              ),
          };
        }),
      })),
      {
        label: translate(intl, 'PUBLIC_NAVIGATION.FOREIGN_SANATORIUMS'),
        locationGroups: foreignCities
          .filter((city) => city.locations.length > 1)
          .map((city) => ({
            label:
              getTranslationByKey(
                'city',
                city.id,
                selectedLocale,
                'name',
                languages,
              ) ?? city.name,
            to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
              ':slug',
              city.seo?.slug ?? '',
            ),
            locations: city.locations.map((location) => ({
              label:
                getTranslationByKey(
                  'location',
                  location.id,
                  selectedLocale,
                  'name',
                  languages,
                ) ?? location.name,
              to: getLocaleUrl(routes.locationPage, selectedLocale)
                .replace(':city', city.seo?.slug ?? '')
                .replace(
                  ':location',
                  getTranslationByKey(
                    'seo',
                    location.seo.id,
                    selectedLocale,
                    'seoSlug',
                    languages,
                  ) ??
                    location.seo?.slug ??
                    '',
                ),
            })),
          })),
        locationsWithoutGroup: foreignCities
          .filter((city) => city.locations.length === 0)
          .map((city) => ({
            label:
              getTranslationByKey(
                'city',
                city.id,
                selectedLocale,
                'name',
                languages,
              ) ?? city.name,
            to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
              ':slug',
              city.seo?.slug ?? '',
            ),
          })),
      },
      {
        label: translate(intl, 'FOOTER.LINK_INTERESTING_ARTICLES'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'FOOTER.MENU_BLOGS_LINK'),
        ),
      },
      {
        label: translate(intl, 'PUBLIC_NAVIGATION.CONTACTS'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'PUBLIC_NAVIGATION.CONTACTS_LINK').toLowerCase(),
        ),
      },
    ];
  };

  const getFooterNavigationItems = () => {
    return [
      {
        label: translate(intl, 'FOOTER.LINK_MAIN'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          '',
        ),
      },
      {
        label: translate(intl, 'FOOTER.LINK_INTERESTING_ARTICLES'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'FOOTER.MENU_BLOGS_LINK'),
        ),
      },
      {
        label: translate(intl, 'FOOTER.LINK_FOR_TOURISTS'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          '',
        ),
      },
      {
        label: translate(intl, 'FOOTER.LINK_ABOUT_US'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          '',
        ),
      },
      {
        label: translate(intl, 'FOOTER.LINK_OUR_PROJECTS'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          '',
        ),
      },
      {
        label: translate(intl, 'FOOTER.LINK_PRESS_INFO'),
        to: getLocaleUrl(routes.dynamicPage, selectedLocale).replace(
          ':slug',
          '',
        ),
      },
    ];
  };

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_USERS'),
          to: routes.users.list,
          icon: faUser as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_BLOGS'),
          to: routes.blogs.list,
          icon: faPen as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_LOCATIONS'),
          to: routes.locations.list,
          icon: faHouseChimney as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_LOCATION_GROUPS'),
          to: routes.locationGroups.list,
          icon: faLayerGroup as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CITIES'),
          to: routes.cities.list,
          icon: faTreeCity as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_AMENITIES'),
          to: routes.amenities.list,
          icon: faBath as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ENQUIRIES'),
          to: routes.enquiries.list,
          icon: faCircleQuestion as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_BASKETS'),
          to: routes.baskets.list,
          icon: faCircleQuestion as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_REVIEWS'),
          to: routes.reviews,
          icon: faComment as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_FAQ'),
          to: routes.faq.list,
          icon: faQuestion as IconProp,
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations.list,
          icon: faGlobe as IconProp,
        },
      ],
    },
  ];

  useEffect(() => {
    if (isAuthenticated) {
      onLocationOptionsFetch();
    }
  }, []);

  useEffect(() => {
    if (selectedLocation && locationOptions.length === 0) {
      return;
    }

    setLocation(
      locationOptions.find(
        (locationOption) => locationOption.id === selectedLocation,
      ),
    );
  }, [locationOptions, selectedLocation]);

  useEffect(() => {
    if (selectedLocation) {
      setIsModalOpen(false);
    }
  }, [selectedLocation]);

  useEffect(() => {
    setTimeout(() => {
      setIsMobileMenuOpen(false);
      window.scroll(0, 0);
    }, 100);
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'pageview',
    });
  }, [routeLocation.key]);

  const getNavigationGroups = () => {
    if (!location) {
      return SIDEBAR_ITEMS;
    }

    return [
      {
        label: translate(intl, 'NAVIGATION.GROUP_LOCATION_SETTINGS'),
        items: [
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_PROGRAMS'),
            to: routes.programs.list,
            icon: faRectangleList as IconProp,
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_ROOMS'),
            to: routes.rooms.list,
            icon: faPersonShelter as IconProp,
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_AGES'),
            to: routes.ages.list,
            icon: faChildren as IconProp,
          },
        ],
      },
      ...SIDEBAR_ITEMS,
    ];
  };

  const getCurrentLocation = () => (
    <CurrentAccount
      img={location?.image?.thumbLocation ?? ''}
      title={
        location?.name ?? translate(intl, 'NAVIGATION.NO_LOCATION_SELECTED')
      }
      subTitle={
        location?.city.name ?? translate(intl, 'NAVIGATION.CLICK_TO_SELECT')
      }
      onClick={() => !isModalOpen && setIsModalOpen(true)}
    >
      <Modal
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={translate(intl, 'NAVIGATION.SELECT_LOCATION')}
        className={styles.locationSelectModal}
      >
        {isModalOpen && (
          <LocationSelectForm onSuccessSelect={() => setIsModalOpen(false)} />
        )}
      </Modal>
    </CurrentAccount>
  );

  if (
    !routeLocation.pathname.includes(routes.login) &&
    !routeLocation.pathname.includes(routes.admin)
  ) {
    return (
      <>
        <PublicNavigation
          onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
          isMobileMenuOpen={isMobileMenuOpen}
          navigationItems={getTopNavigationItems()}
        />
        <div
          className={cx(styles.publicContainer, {
            [styles.minPublicContainer]: routeLocation.pathname.includes(
              routes.checkout.replace('/:locale', ''),
            ),
          })}
        >
          {children}
        </div>
        <Footer navigationItems={getFooterNavigationItems()} />
      </>
    );
  }

  if (routeLocation.pathname.includes(routes.login)) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar
            navigationGroups={getNavigationGroups()}
            currentAccount={getCurrentLocation()}
          />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getCurrentLocation()}
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items.map((item) => (
                <NavLink
                  key={item.label}
                  to={item.to}
                  className={({ isActive }) =>
                    cx(styles.navigationItem, {
                      [styles.activeSubItem]: isActive,
                    })
                  }
                >
                  {item.label}
                </NavLink>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocation: state.location.selectedLocation,
  locationOptions: state.location.locationOptions,
  citiesList: state.city.citiesList,
  publicTopNavigationItems: state.city.publicTopNavigationItems,
  locationGroupsList: state.locationGroup.locationGroupsList,
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLocationOptionsFetch: () =>
    dispatch(locationService.fetchLocationOptions()),
  onCitiesFetch: (tableParams: ListParams) =>
    dispatch(cityService.fetchCities(tableParams)),
  onPublicTopNavigationItemsFetch: () =>
    dispatch(cityService.fetchPublicTopNavigationItems()),
  onLocationGroupsFetch: (tableParams: ListParams) =>
    dispatch(locationGroupService.fetchLocationGroups(tableParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
